import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { Box, ThemeProvider } from "@mui/material";
import { FormTab, TopToolbar, useNotify
} from "react-admin";
import CircularProgress from "@material-ui/core/CircularProgress";
import ViewListIcon from "@material-ui/icons/ViewList";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import ExitToApp from "@material-ui/icons/ExitToApp";
import LockIcon from "@material-ui/icons/Lock";
import NoEncryptionIcon from "@material-ui/icons/NoEncryption";
import { Button as ButtonReactAdmin } from "react-admin";
import { Button as ButtonMui } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslate } from "ra-core";
import muiTheme from "../../../helper/muiTheme";
import botProvider from "../../../synapse/botProvider";
import { Pagination } from "../../common/Pagination";
import { logger } from "../../../utils/logger";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CancelIcon from "@material-ui/icons/Cancel";

function CustomToolbar() {
  return (
    <Box
      sx={{
        display: "flex",
        fontWeight: "bold",
        p: 2,
        justifyContent: "space-between",
      }}
    >
      <GridToolbarQuickFilter />
    </Box>
  );
}

const BotInRoom = props => {
  const notify = useNotify();

  const [room, setRoom] = useState({});
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const translate = useTranslate();

  const handleDialogClose = () => setOpenConfirm(false);

  const handleConfirm = (botId, roomId) => {
    setIsLoading(true);
    botProvider
      .leaveRooms(botId, [roomId])
      .then(() => {
        notify("resources.bots.actions.leave_room_success", {
          type: "success",
        });
        props.deleteRooms([roomId]);
      })
      .catch(error => {
        logger.error(error);
        notify("resources.common.failed_to_operate_please_refresh", {
          type: "error",
        });
      })
      .finally(() => {
        setOpenConfirm(false);
        setIsLoading(false);
      });
  };

  const handleLeaveRoom = e => {
    e.preventDefault();
    e.stopPropagation();
    setOpenConfirm(true);
  };
  const columns = [
    {
      field: "roomId",
      headerName: "Room-ID",
      width: 500,
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "name",
      headerName: "Name",
      width: 400,
      sortable: true,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "encryption",
      headerName: "Encryption",
      width: 150,
      sortable: true,
      disableColumnMenu: true,
      flex: 0,
      valueFormatter: () => {
        return "";
      },
      renderCell: params => {
        return params.value ? (
          <LockIcon style={{ color: muiTheme.palette.success.light }} />
        ) : (
          <NoEncryptionIcon color="error" />
        );
      },
    },
    {
      field: "isPublic",
      headerName: "Private",
      width: 150,
      sortable: true,
      disableColumnMenu: true,
      flex: 0,
      valueFormatter: () => {
        return "";
      },
      renderCell: params => {
        return params.value ? (
          <ClearIcon color="error" />
        ) : (
          <CheckIcon style={{ color: muiTheme.palette.success.light }} />
        );
      },
    },
    // {
    //   field: "power_level",
    //   headerName: "Role",
    //   width: 200,
    //   sortable: true,
    //   disableColumnMenu: true,
    //    flex: 0,
    //   renderCell: CardRoleGrid,
    // },
    {
      field: "actions",
      headerName: "",
      width: 300,
      sortable: false,
      disableColumnMenu: true,
      flex: 0,
      renderCell: params => {
        const record = { ...params.row };

        return (
          <TopToolbar
            style={{
              margin: "0 0 15px 10px",
            }}
          >
            {
              <ButtonMui
                style={{
                  textTransform: "capitalize",
                  backgroundColor: muiTheme.palette.error.main,
                }}
                variant="contained"
                color="primary"
                label="resources.bots.actions.leave_room"
                onClick={e => {
                  setRoom(record);
                  handleLeaveRoom(e);
                }}
              >
                <ExitToApp style={{ marginRight: "10px" }} />
                {translate("resources.bots.actions.leave")}
              </ButtonMui>
            }
          </TopToolbar>
        );
      },
    },
  ];

  return (
    <FormTab
      {...props}
      label="resources.bots.tabs.room"
      icon={<ViewListIcon />}
    >
      {props.fetchRoom ? (
        <div
          style={{
            margin: "75px auto 100px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={50} thickness={2} />
        </div>
      ) : (
        <ThemeProvider theme={muiTheme}>
          <Box
            marginBottom="10px"
            sx={{
              maxWidth: "calc(100vw - 295px)",
              height: "70vh",
            }}
          >
            <DataGrid
              pagination
              style={{ borderWidth: 0 }}
              rows={props.rooms}
              columns={columns}
              getRowId={row => row?.roomId}
              initialState={{
                pagination: { paginationModel: { pageSize: 50 } },
                sorting: {
                  sortModel: [{ field: "displayname", sort: "asc" }],
                },
              }}
              pageSizeOptions={[10, 25, 50]}
              slots={{
                pagination: Pagination,
                toolbar: CustomToolbar,
              }}
            />
          </Box>
          <Dialog aria-labelledby="reset-dialog-title" open={openConfirm}>
            <DialogTitle id="reset-dialog-title">
              {translate("resources.bots.actions.leave_confirm_title", {
                roomName: room.name ? room.name : room.roomId,
              })}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="reset-dialog-description">
                {translate("resources.bots.actions.leave_confirm_content", {
                  roomName: room.name ? room.name : room.roomId,
                })}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <ButtonReactAdmin
                onClick={handleDialogClose}
                color="inherit"
                label="Cancel"
              >
                <CancelIcon />
              </ButtonReactAdmin>
              {isLoading ? (
                <CircularProgress
                  style={{ height: "20px", width: "20px" }}
                  color="primary"
                />
              ) : (
                <ButtonReactAdmin
                label="Confirm"
                onClick={() => {
                  handleConfirm(props.id, room.roomId);
                }}
                  // onClick={handleConfirm(props.id, room.roomId)}
                  color="primary"
                  // disabled={isInvalid && deactivated}
                >
                  <CheckIcon />
                </ButtonReactAdmin>
              )}
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      )}
    </FormTab>
  );
};

export default BotInRoom;
