import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Toolbar, SaveButton } from "react-admin";
import CircularProgress from "@material-ui/core/CircularProgress";
import ContentSave from "@material-ui/icons/Save";

/**
 * Custom submit button for resource forms.
 *
 * @typedef {Object} Props the props you can use (other props are injected by the <Toolbar>)
 * @prop {boolean} loading Hide/Show spinner icon.
 *
 * @param {Props} props
 * @param {Boolean} loading
 * @param {Array<String>} pathnameDisabled
 *
 * @example // with button
 *
 * <TabbedForm {...props} toolbar={<ToolbarSaveButton pathnameDisabled={pathnameDisabled} {...props} loading={loading} />}>
 * <FormTab></FormTab>
 * </TabbedForm>
 */
const ToolbarSaveButton = ({ loading, pathnameDisabled, ...props }) => {
  const [isHideToolbar, setHideToolbar] = useState(false);
  useEffect(() => {
    const pathname = props?.location.pathname;
    const  isHideToolbar = !!pathnameDisabled?.find((item) => {
      return pathname === item;
    });
    setHideToolbar(isHideToolbar);
  }, [pathnameDisabled]);


  return (
    isHideToolbar ?
      null :
      <Toolbar {...props}>
        <SaveButton
          icon={
            loading ? (
              <CircularProgress size={18} thickness={4} />
            ) : (
              <ContentSave />
            )
          }
          disabled={loading}
        />
      </Toolbar>
  );
};

ToolbarSaveButton.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleSubmitWithRedirect: PropTypes.func,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  record: PropTypes.any,
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
  resource: PropTypes.string,
  saving: PropTypes.bool,
  submitOnEnter: PropTypes.bool,
  undoable: PropTypes.bool,
  validating: PropTypes.bool,
  width: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  loading: PropTypes.bool,
  pathnameDisabled: PropTypes.arrayOf(PropTypes.string)
};

ToolbarSaveButton.defaultProps = {
  submitOnEnter: true,
};

export default ToolbarSaveButton;
