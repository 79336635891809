import Contacts from "@material-ui/icons/Contacts";
import React, { useState } from "react";
import { BooleanInput, FormDataConsumer, FormTab } from "react-admin";

export const EAllowToChange3pid = {
  EMAIL: "ALLOW_TO_CHANGE_EMAIL",
  PHONE: "ALLOW_TO_CHANGE_PHONE",
};

export const _3pidSettings = props => {
  const [settings, setSettings] = useState(null);

  const onAllowToChangeEmail = (value) => {
    setSettings(EAllowToChange3pid.EMAIL, value);
  }

  const onAllowToChangePhone = (value) => {
    setSettings(EAllowToChange3pid.PHONE, value);
  }

  return (
    <FormTab
      {...props}
      label="resources.settings.tabs._3pid"
      icon={<Contacts />}
    >
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          return <div>
            <BooleanInput
                source={EAllowToChange3pid.EMAIL}
                label="resources.settings.fields._3pid_allow_to_change_email"
                fullWidth
                onChange={onAllowToChangeEmail}
            />
            <BooleanInput
                source={EAllowToChange3pid.PHONE}
                label="resources.settings.fields._3pid_allow_to_change_phone"
                fullWidth
                onChange={onAllowToChangePhone}
            />
          </div>;
        }}
      </FormDataConsumer>
    </FormTab>
  );
};
